import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';

import apiService from '../services/apiService';

import { VALIDATION_SAID, VALIDATION_SAID_SUCCESS } from '../store/actionTypes';
import { validationSaidSuccess } from '../actions';
import { ScreenBusUtils } from '../hooks/useEventBus';
import ErrorUtils from '../utils/errorUtils';
import { ErrorCode } from '../constants';

export function validationSaidEpic(action$) {
  return action$.pipe(
    ofType(VALIDATION_SAID),
    switchMap(action =>
      apiService.validationSaid(action.payload).pipe(
        map(res => validationSaidSuccess(res)),
        catchError(error => ErrorUtils.getApiAction(error)),
      ),
    ),
  );
}

export const validationSaidSuccessEpic = action$ =>
  action$.pipe(
    ofType(VALIDATION_SAID_SUCCESS),
    switchMap(({ payload }) => {
      if (payload && payload.customer && payload.customer.inactiveProfile) {
        return ErrorUtils.getApiAction({
          response: {
            errors: [{ errorCode: ErrorCode.NONE_ACTIVATED_GPROFILE }],
          },
        });
      }

      return of(ScreenBusUtils.gotoScreenOtp());
    }),
  );

export default combineEpics(validationSaidEpic, validationSaidSuccessEpic);
